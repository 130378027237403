<script lang="ts">
	import logistika from "../assets/images/conferences-poster.webp?h=600&imagetools";
	import conferenceLine from "../../../core/assets/icons/conference-line.svg?raw";
	import DecoratedHeadline from "./DecoratedHeadline.svelte";
	import Icon from "./Icon.svelte";
	import Link from "./Link.svelte";
	import conferencesVideoMp4 from "../assets/videos/conferences-video.mp4";
	import { nbspify } from "../../../core/utils/nbspify.js";
</script>

<div class="mt-36 flex w-full flex-col items-center lg:pb-72">
	<DecoratedHeadline class="mb-16">{nbspify("Naše konference")}</DecoratedHeadline>
	<div class="relative h-[37.5rem] w-full">
		<Icon
			icon={conferenceLine}
			class="absolute bottom-[38%] left-1/2 top-0 z-10 !aspect-auto w-[50rem] -translate-x-1/2 text-white sm:bottom-[34%] sm:w-full"
		/>
		<video poster={logistika} autoplay muted loop playsinline class="absolute inset-0 h-[37.5rem] w-full object-cover">
			<source src={conferencesVideoMp4} type="video/mp4" />
		</video>
		<div class="bg-primary absolute inset-0 h-[37.5rem] w-full opacity-50"></div>
		<div class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-center text-white">
			<p class="font-quicksand text-xl uppercase tracking-[0.15em] sm:text-[4rem]">
				Logistika <br /> <span class="font-bold">{nbspify("v praxi")}</span>
			</p>
			<p class="text-base font-bold sm:text-[1.5rem]">{nbspify("10. až 11. dubna 2025")}</p>
		</div>

		<div class="absolute bottom-0 left-0 hidden w-full translate-y-[60%] lg:left-1/2 lg:block lg:-translate-x-1/2">
			<div class="mx-auto max-w-[56.25rem]">
				<div class="border-gray flex flex-col gap-2 border bg-white p-6">
					<p class="text-primary text-[1.5rem] font-extrabold">
						{nbspify(
							"Pomocí série konferencí Logistika v praxi chceme sdílet realizace, na kterých jsme se podíleli, ústy našich zákazníků",
						)}
					</p>
					<p class="text-gray-400 md:text-xs">
						{nbspify(
							"Snažíme se, abyste vy, naši obchodní partneři, rostli spolu s námi a k tomu je nezbytné propojování kontaktů a sdílení zkušeností.",
						)}
						<br />
						{nbspify(
							"V BITO vždy přináší svým zákazníkům komplexní logistická řešení na míru. Díky našim bohatým zkušenostem dokážeme najít správnou aplikaci pro každé průmyslové odvětví. Našim zákazníkům dodáváme kromě skladového vybavení také část našeho já, know-how našich zaměstnanců, sdílení referencí našich zákazníků, zkrátka něco, co si můžete ověřit v praxi.",
						)}
					</p>
				</div>
				<Link
					href="https://www.logistikavpraxi.cz/"
					target="_blank"
					asButton
					variant="primary"
					class="w-[7.5rem] sm:w-[13.75rem]">{nbspify("Zjistit více")}</Link
				>
			</div>
		</div>
	</div>
</div>

<div class="w-full pb-32 lg:hidden">
	<div class="mx-auto max-w-[56.25rem]">
		<div class="border-gray flex flex-col gap-2 border bg-white p-6">
			<p class="text-primary font-bold">
				{nbspify(
					"Pomocí série konferencí Logistika v praxi chceme sdílet realizace, na kterých jsme se podíleli, ústy našich zákazníků",
				)}
			</p>
			<p class="text-2xs text-gray-400">
				{nbspify(
					"Snažíme se, abyste vy, naši obchodní partneři, rostli spolu s námi a k tomu je nezbytné propojování kontaktů a sdílení zkušeností.",
				)}
				<br />
				{nbspify(
					"V BITO vždy přináší svým zákazníkům komplexní logistická řešení na míru. Díky našim bohatým zkušenostem dokážeme najít správnou aplikaci pro každé průmyslové odvětví. Našim zákazníkům dodáváme kromě skladového vybavení také část našeho já, know-how našich zaměstnanců, sdílení referencí našich zákazníků, zkrátka něco, co si můžete ověřit v praxi.",
				)}
			</p>
		</div>
		<Link
			href="https://www.logistikavpraxi.cz/"
			target="_blank"
			asButton
			variant="primary"
			class="w-[7.5rem] sm:w-[13.75rem]">{nbspify("Zjistit více")}</Link
		>
	</div>
</div>
